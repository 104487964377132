import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import { Helmet } from "react-helmet";
import { Accordion } from "../components/Accordion/Accordion";

const qas = [
  {
    q: "Do I need any jewellery making experience?",
    a:
      "No previous experience needed. \n" +
      "\n" +
      "I recommend starting with the stacking ring class if you’re completely new. It covers a lot of basics.",
  },
  {
    asHtml: true,
    q: "Are tools and materials included in the price?",
    a:
      "No. \n" +
      "\n" +
      "You can find a list of all essential tools <a href='/blog/post/what-tools-do-I-need-for-wax-carving' class='text-indigo-400'>here.</a> I use a variety of them in every class.\n" +
      "Each class lists what tools and wax you need.\n" +
      "\n" +
      "You can buy the tools and materials from all jewellery suppliers online or in shops. " +
      "There's a list of tool suppliers <a href='/tools-and-supplies' class='text-indigo-400'>here.</a>\n" +
      "\n" +
      "Have a google though if no company is listed for your country. Every country has their own suppliers!\n",
  },

  {
    asHtml: true,
    q: "What color wax should I get?",
    a: `I recommend blue because it’s available everywhere. But really whatever color you prefer working with is fine.  

I’ve been testing all the different wax colors on the blog, so have a look <a href='/blog/post/what-wax-color-should-I-get' class='text-indigo-400'>here</a> if you want to know more about each wax color and what they’re good at.`,
  },
  {
    q: "Can I get a refund?",
    a:
      "No, sorry. If you’ve seen the class, there is no unseeing it. The material is available to you indefinitely so take your time working through it.  \n" +
      "\n",
  },
  {
    q: "How long are the classes?",
    a:
      "Every class has a different duration, but around 90 minutes is the average. \n" +
      "\n" +
      "A class is divided in multiple short videos. This way it’s easy to drop in and out of it, without losing your place in a long video. \n",
  },
  {
    q: "Can I download the material?",
    a:
      "You can’t download the videos. They’re big it’ll be a hassle. You can play them in your user dashboard as often as you like. \n" +
      "\n" +
      "You can download the pdf handouts for easy reference at your bench.\n",
  },
  {
    q: "What if I have a question?",
    a:
      "Every class has an area where you can ask questions. I answer questions every Monday. \n" +
      "\n" +
      "Once I answer the question it gets published to the class. This way everyone with access to the class can read through the questions and answers and learn from them.\n",
  },
  {
    q: "Why are you teaching this? I can do this in metal?",
    a:
      "Every piece of jewellery can be made in different ways and one of those is wax. \n" +
      "\n" +
      "If you know how to do it in metal, you might enjoy learning how to do it in wax! \n",
  },
  {
    q: "This is so time consuming. Why don’t I do this in CAD?",
    a:
      "I’m teaching wax carving, not CAD. Everything is probably quicker in CAD.\n" +
      "\n" +
      "I’m sure you can find a good CAD course online!",
  },
  {
    q: "What terminology do you use?",
    a: "I learned in Ireland so millimeters not gauges, centimeters not inches, grams not ounces, ring sizes come in letters not numbers and prices are in euro. In written: jewellery not jewelry!",
  },
];

const faq = () => (
  <Layout title="Wax Carvers - Frequently Asked Questions">
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: [
            qas.map((it) => ({
              "@type": "Question",
              name: it.q,
              acceptedAnswer: {
                "@type": "Answer",
                text: it.a,
              },
            })),
          ],
        })}
      </script>
    </Helmet>
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <PageTitle title="Frequently asked questions" />
        <div className="mt-12">
          {qas.map((it) => (
            <Accordion
              key={it.q}
              content={{ title: it.q, text: it.a, asHtml: it.asHtml }}
            />
          ))}
        </div>
      </div>
    </div>
    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>
);

export default faq;
